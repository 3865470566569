import { IQuoteLine, IRates } from 'models/simple_quotes.model'

export const calculateLine = (
	rates: IRates[],
	line: IQuoteLine,
	field?: string
) => {
	if (
		field === 'quantity' &&
		(!line.erect_and_dismantle_is_dirty && !line.weekly_hire_fee_is_dirty)
	) {
		const rate = rates.find((rate) => rate.service === line.type)?.fee
		if (!rate) {
			return line
		}
		const erectDismantleFee = Number(rate) * Number(line.quantity)
		const weekHireFee = Number(
			erectDismantleFee * (Number(line.percentage_weekly_hire_fee) / 100)
		).toFixed(2)
		const total = Number(erectDismantleFee) + Number(weekHireFee)

		line.erect_and_dismantle = Number(erectDismantleFee.toFixed(2))
		line.weekly_hire_fee = Number(weekHireFee)
		line.total = Number(total.toFixed(2))
	} else {
		const erectDismantleFee = line.erect_and_dismantle
		const weekHireFee = line.weekly_hire_fee
		line.total = Number(erectDismantleFee) + Number(weekHireFee)
	}

	return line
}
