import { IQuoteAdditionalLines, IRates } from 'models/simple_quotes.model'

const initLine: IQuoteAdditionalLines = {
	id: null,
	type: '',
	description: '',
	duration_quantity: 0,
	fixed_charge: 0,
	total_cost: 0,
	fixed_charge_is_dirty: false,
}

const addNewQuoteLine = (
	lines: IQuoteAdditionalLines[]
): IQuoteAdditionalLines[] => {
	lines.push(initLine)
	return lines
}

const removeQuoteLine = (
	index: number,
	quote_lines: IQuoteAdditionalLines[]
): IQuoteAdditionalLines[] => {
	const newQuoteLines = [...quote_lines]
	newQuoteLines.splice(index, 1)
	return newQuoteLines
}

const updateQuoteLine = (
	index: number,
	quote_lines: IQuoteAdditionalLines[],
	field: string,
	value: string | number
): IQuoteAdditionalLines[] => {
	const newQuoteLines = quote_lines.map((line, i) => {
		if (i === index) {
			return {
				...line,
				[field]: value,
			}
		}
		return line
	})
	return newQuoteLines
}

const calculateLines = (
	index: number,
	lines: IQuoteAdditionalLines[],
	rates: IRates[],
	field: string
): IQuoteAdditionalLines[] => {
	const newLines = lines.map((line, i) => {
		const rate = rates.find((rate) => rate.service === line.type)?.fee
		if (!rate) return line
		if (i === index) {
			if (
				!line.fixed_charge_is_dirty &&
				(field === 'duration_quantity' || field === 'type')
			) {
				const newLine: IQuoteAdditionalLines = {
					...line,
					fixed_charge: Number(rate),
					total_cost: Number(rate) * Number(line.duration_quantity),
				}
				return newLine
			} else {
				const newLine: IQuoteAdditionalLines = {
					...line,
					fixed_charge: Number(line.fixed_charge),
					fixed_charge_is_dirty:
						field === 'fixed_charge' ? true : line.fixed_charge_is_dirty,
					total_cost: Number(line.fixed_charge),
				}
				return newLine
			}
		}
		return line
	})
	return newLines
}

export const quoteAdditionalLinesFn = {
	addNewQuoteLine,
	removeQuoteLine,
	updateQuoteLine,
	calculateLines,
}
