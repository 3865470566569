import { Spinner } from 'common'
import { QuoteAcceptLine } from 'components/SimpleQuotes/QuoteAccept'
import { QuoteDetails } from 'components/SimpleQuotes/QuoteAccept/QuoteDetails'
import { QuoteLineDTO, QuoteLinePhotoDTO } from 'models/quotes.model'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SimpleQuoteServices } from 'services'

export const QuoteAcceptancePage = () => {
	const { token } = useParams()
	const [isAccepted, setAccepted] = useState(false)
	const [comments, setComments] = useState('')
	const [orderNumber, setOrderNumber] = useState('')
	const [quote_lines_data, setQuoteLinesData] = useState([] as QuoteLineDTO[])

	const { data: quote, isLoading: quote_loading } =
		SimpleQuoteServices.useQuoteByToken(token || '')
	const { data: quote_lines, isLoading: quote_lines_loading } =
		SimpleQuoteServices.useQuoteLinesByToken(token || '')
	const { data: quote_addons, isLoading: quote_addons_loading } =
		SimpleQuoteServices.useQuoteAddonsByToken(token || '')
	const { data: quote_rates, isLoading: quote_rates_loading } =
		SimpleQuoteServices.useQuoteRatesByToken(token || '')
	const { data: quote_files, isLoading: quote_files_loading } =
		SimpleQuoteServices.useQuoteFilesByToken(token || '')
	const { data: quote_zones, isLoading: quote_zones_loading } =
		SimpleQuoteServices.useQuoteZonesByToken(token || '')

	useEffect(() => {
		if (!quote_lines || !quote_files) return
		const quote_line_display = quote_lines.map((line: any) => {
			// Filter quote_files comparing the file.section with line.zone_id, make it return an array of QuoteLinePhotoDTO where the imageUrl = file.url and imageDescription = file.description
			const images = quote_files
				.filter((file: any) => file.section === line.zone_id)
				.map((file: any) => {
					const image = {
						imageUrl: file.url,
						imageDescription: file.description,
					} as QuoteLinePhotoDTO

					return image
				}) as QuoteLinePhotoDTO[]

			return {
				sectionNumber: line.zone_id,
				sectionName: line.zone_label,
				itemDescription: line.description,
				images: images,
				quantity: line.quantity,
				price: line.total,
				accepted: false,
			}
		})
		setQuoteLinesData(quote_line_display)
	}, [quote_lines, quote_files])

	if (
		quote_loading ||
		quote_lines_loading ||
		quote_addons_loading ||
		quote_rates_loading ||
		quote_files_loading ||
		quote_zones_loading
	) {
		return <Spinner />
	}
	if (!quote) return <h1>Invalid Link</h1>
	console.log(quote)
	const handleAcceptance = () => {
		// Handle quote acceptance logic here
		if (isAccepted) {
			// Perform actions when quote is accepted
		}
	}

	console.log('Quote_Lines', quote_lines)
	console.log('Quote_Rates', quote_rates)
	console.log('Quote_Files', quote_files)
	console.log('Quote_Addons', quote_addons)
	console.log('Quote_Zones', quote_zones)

	if (!quote_lines_data) return <Spinner />

	return (
		<div className="quote-acceptance flex flex-col min-h-screen justify-between items-center p-4 sm:w-full">
			<div className="sm:w-4/5 lg:w-3/5">
				<QuoteDetails quote={quote} />

				<div className="quote-items mt-6">
					<h2 className="text-2xl font-semibold text-left mb-4">Quote Items</h2>
					{quote_lines_data.map((line: QuoteLineDTO, index: number) => (
						<QuoteAcceptLine line={line} index={index} />
					))}
				</div>
			</div>

			<div className="w-4/5 lg:w-3/5 p-4">
				<div className="terms-section font-bold">
					<h2>Terms and Conditions</h2>
					<p className="whitespace-pre-line">{quote.terms}</p>
					<p className="font-bold mt-4">Kind Regards,</p>
					<p className="font-bold italic">{quote.estimatorData.staff_name}</p>
					<p className="font-bold italic">Pricing Estimator</p>
					<p className="font-bold">Mobile: {quote.estimatorData.mobile}</p>
					<p className="font-bold">Email: {quote.estimatorData.email}</p>
				</div>

				<div className="accept-section mt-6 p-4">
					<div className="space-y-4">
						<div>
							<textarea
								placeholder="Comments"
								value={comments}
								onChange={(e) => setComments(e.target.value)}
								className="w-full p-2 border rounded"
							/>
						</div>
						<div>
							<input
								type="text"
								placeholder="Order Number"
								value={orderNumber}
								onChange={(e) => setOrderNumber(e.target.value)}
								className="w-full p-2 border rounded"
							/>
						</div>
						<div className="flex items-center">
							<input
								type="checkbox"
								checked={isAccepted}
								onChange={() => setAccepted(!isAccepted)}
								className="mr-2"
							/>
							<label>
								I{' '}
								<span className="font-bold italic">
									{quote.contactData.name}
								</span>{' '}
								hereby agree to the terms of above quote as described on{' '}
								<span className="font-bold italic">
									{new Date().toLocaleString('en-NZ', {
										timeZone: 'Pacific/Auckland',
										dateStyle: 'long',
										timeStyle: 'short',
									})}
								</span>
							</label>
						</div>
						<button
							onClick={handleAcceptance}
							className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none">
							Accept Quote
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default QuoteAcceptancePage
